body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

body {
  position: relative;
  background-size: cover;
  background-image: url("/img/background.png");
  background-color: #92d6eb;
}

body:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 174px;
  height: 182px;
  margin-left: -87px;
  margin-top: -91px;
  opacity: 0.2;
}

/* Hide the chat header and close/open buttons */
.Twilio-EntryPoint,
.Twilio-Icon-Close,
.Twilio-MainHeader {
  display: none !important;
}

/* Make chat window fill full height of screen */
.Twilio-MainContainer {
  max-height: none !important;
}

/* Add some padding to the chat window */
.Twilio-MessageInput {
  padding: 1rem 0;
}

@media only screen and (min-width: 600px) {
  .Twilio-MessageInput {
    padding: 3.5rem 0;
  }
}

/* Add margin to top of the 'start new chat' message */
.Twilio-MessageCanvasTray {
  margin-top: 3rem;
}

/* Limit width of pre engagement form */
.Twilio-PreEngagementCanvas {
  max-width: 640px;
}

/* Pre engagement form inputs */
.Twilio-PreEngagementCanvas input,
.Twilio-PreEngagementCanvas div[id^=select] {
  background-color: #FFF;
}

/* Make the intro text and final (required) question bold */
.Twilio-PreEngagementCanvas .Twilio-DynamicForm div:last-of-type,
.Twilio-PreEngagementCanvas .Twilio-DynamicForm div:first-of-type {
  font-weight: bold;
}

.pre-intro {
  font-weight: bold;
  font-size: 14px;
}

/* Mid form text */
.Twilio-PreEngagementCanvas .Twilio-DynamicForm div:nth-child(6) {
  font-weight: bold;
  margin-bottom: -3rem;
  margin-top: 2rem;
}

input[name="optional"] {
  display: none;
}

/* Stop the privacy policy link from being pushed to the bottom of the page */
.Twilio-PreEngagementCanvas .Twilio-DynamicForm {
  flex-grow: 0;
}

.privacy-policy {
  margin-top: 1rem;
  text-align: right;
}

.privacy-policy a {
  color: #333;
}

/* Leave space for the exit site button */
.Twilio-PreEngagementCanvas,
.Twilio-MessagingCanvas {
  padding-top: 5rem !important;
}

.exit-site button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #3c3735;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 1.25rem;
  font-size: 1.5rem;
  font-family: "Open Sans Condensed", sans-serif;
  line-height: 1;
  cursor: pointer;
  border: none;
}

.exit-site button:hover {
  color: #f06321;
}

.exit-site button span {
  vertical-align: middle;
}

.exit-site svg {
  fill: currentColor;
  width: 1.25em;
  height: 1.25em;
  vertical-align: middle;
  flex-shrink: 0;
  margin-right: 0.25rem;
}

.closeButton {
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  background: #1976d2;
  letter-spacing: 2px;
  border: 0;
  font-weight: bold;
  padding: 0 16px;
  height: 28px;
  align-self: center;
  font-size: 12px;
  border-radius: 25px;
  flex-shrink: 0;
}

.closeButton:hover {
  background: #2d3091;
}

/* Automated VPN message */
.vpn-message {
  background-color: #ffffff;
  padding: 1rem;
  width: 100%;
  color: #2d3091;
}

.vpn-message * {
  max-width: 640px;
  margin: 1rem auto;
}

.vpn-message a {
  color: #2d3091;
  font-weight: bold;
}